<template>
  <div v-if="challenge">
    <b-card no-body>
      <b-card-header>{{challenge.away.name}} at {{challenge.home.name}}</b-card-header>
      <b-card-body>
        <Faceoff :challenge="challenge" />
      </b-card-body>
    </b-card>
    <hr />
    <b-card no-body>
      <b-card-header>Submit Results</b-card-header>
      <b-card-body>
        <template v-if="challenge">
          <!-- <b-form-group label="Line:">
            <b-form-select v-model="line" :options="lineOptions" size="sm"></b-form-select>
          </b-form-group>
          <b-form-group label="Spread:">
            <b-form-select v-model="spread" :options="spreadOptions" size="sm"></b-form-select>
          </b-form-group>
          <b-form-group label="Spread:">
            <b-form-select v-model="total" :options="totalOptions" size="sm"></b-form-select>
          </b-form-group> -->
          <label>{{ challenge.home.name }} Points</label>
          <b-form-group>
            <b-form-input v-model="homePoints" size="sm"></b-form-input>
          </b-form-group>
          <label>{{ challenge.away.name }} Points</label>
          <b-form-group>
            <b-form-input v-model="awayPoints" size="sm"></b-form-input>
          </b-form-group>

        </template>
        <b-button variant="danger" style="margin-top:10px" class="btn-block" @click="submitResult">Submit</b-button>
        <p>{{message}}</p>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import { api } from "@/utils/api";
import Faceoff from "@/components/Faceoff";

export default {
  name: "SubmitResult",
  components: {
    Faceoff,
  },
  data() {
    return {
      challenge: null,
      // line: null,
      // lineOptions: [{ value: null, text: "Please Select Winner" }],
      // spread: null,
      // spreadOptions: [{ value: null, text: "Please Select the Spread" }],
      // total: null,
      homePoints: null,
      awayPoints: null,
      // totalOptions: [
      //   { value: null, text: "Please Select Over or Under" },
      //   { value: "OV", text: "Over" },
      //   { value: "UN", text: "Under" },
      // ],
      message: "",
    };
  },
  created() {
    let self = this;
    api(
      `query { challenge(id:"${this.$route.params.id}"){ id spread totalpoints line { name } home { id name logo mascot } away { id name logo mascot } eventDate eventTime bets { id } } }`
    ).then((data) => {
      self.challenge = data.challenge;

      // self.lineOptions.push({
      //   value: data.challenge.home.id,
      //   text: data.challenge.home.name,
      // });
      // self.lineOptions.push({
      //   value: data.challenge.away.id,
      //   text: data.challenge.away.name,
      // });

      // self.spreadOptions.push({
      //   value: data.challenge.home.id,
      //   text: data.challenge.home.name,
      // });
      // self.spreadOptions.push({
      //   value: data.challenge.away.id,
      //   text: data.challenge.away.name,
      // });
    });
  },
  methods: {
    submitResult() {
      let self = this;
      if (this.homePoints && this.awayPoints) {
        this.message = "";
        api(
          `mutation { results(cid:"${this.challenge.id}", homePoints: ${this.homePoints}, awayPoints: ${this.awayPoints}){ code message } }`
        ).then((data) => {
          self.message = data.results.message;
          this.$router.push({ name: "Challenges" });
        });
      } else {
        this.message = "Error: Not all values are filled out";
      }
    },
  },
};
</script>
